@import 'designSystem/device-sizes.scss';
@font-face {
  font-family: "GeneralSansRegular";
  src: url("fonts/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GeneralSans";
  src: url("fonts/GeneralSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

$general-sans-regular: "GeneralSansRegular", sans-serif;
$general-sans: "GeneralSans", sans-serif;
$chivo: Chivo;
$bodySmall: 0.875rem;

h1,
.title1 {
  font-family: $chivo;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.25rem;
  @media ($phone) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
h2,
.title2 {
  font-family: $chivo;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
  @media ($phone) {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
}
h3,
.title3 {
  font-family: $chivo;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.75rem;
  @media ($phone) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
h4,
.title4 {
  font-family: $chivo;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5rem;
  @media ($phone) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.s1,
.subtitle1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
  @media ($phone) {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}
.s2,
.subtitle2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  @media ($phone) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}
.s3,
.subtitle3 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  @media ($phone) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  &.bold,
  .bold {
    font-family: $general-sans;
    font-weight: 500;
  }
}

.button-large {
  font-size: 1rem;
  font-family: $general-sans-regular;
  font-weight: 400;
  line-height: 1rem;
  @media ($phone) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.button-small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  @media ($phone) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.body-large,
.body2 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  @media ($phone) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &.bold,
  .bold {
    font-family: $general-sans;
    font-weight: 500;
  }
}
.body-small,
.caption {
  font-size: $bodySmall;
  font-weight: 400;
  line-height: 1.25rem;
  @media ($phone) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &.bold,
  .bold {
    font-family: $general-sans;
    font-weight: 500;
  }
}
.tiny {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
  @media ($phone) {
    font-size: 0.625rem;
    line-height: 0.625rem;
  }
  &.bold,
  .bold {
    font-family: $general-sans;
    font-weight: 500;
  }
}

$title3-font-size: 1.25rem;
$title3-font-weight: 400;

$body1-font-size: 1rem;
$body1-font-weight: 400;

$label1-font-size: 1rem;
$label1-font-weight: 500;

$label2-font-size: 0.875rem;
$label2-font-weight: 600;

p,
h1,
h2,
h3,
h4,
a,
div,
input,
span,
label {
  margin: 0;
  // &.label1 {
  //   font-size: $label1-font-size;
  //   font-weight: $label1-font-weight;
  // }
  // &.label2 {
  //   font-size: $label2-font-size;
  //   font-weight: $label2-font-weight;
  // }

  // &.body1 {
  //   font-family: $chivo;
  //   font-size: $body1-font-size;
  //   font-weight: $body1-font-weight;
  // }

  // &.body2 {
  //   font-family: $chivo;
  //   font-size: 0.875rem;
  //   font-weight: 500;
  // }
  // &.body3 {
  //   font-family: $chivo;
  //   font-size: 0.75rem;
  //   font-weight: 500;
  // }

  // &.balloon {
  //   font-family: $chivo;
  //   font-size: 1.083;
  //   font-weight: 500;
  //   line-height: 130%;
  // }
  // &:link,
  // .link {
  //   font-family: $chivo;
  //   font-weight: 700;
  //   text-decoration: inherit;
  //   cursor: pointer;
  // }
}
