/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$agentportal-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$agentportal-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$agentportal-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$agentportal-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $agentportal-primary,
        accent: $agentportal-accent,
        warn: $agentportal-warn,
      ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($agentportal-theme);

@import 'designSystem/device-sizes.scss';
@import 'designSystem/styles-colors.scss';
@import 'designSystem/styles-typography.scss';

html,
body {
  height: 100%;
}
body {
  a {
    text-decoration: none;
  }
  div {
    box-sizing: border-box;
  }
  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

  .agent {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }
  }
  .navigation-btn {
    display: flex;
    gap: 20px;
    width: 100%;
    > div:not(.btn) {
      flex: 1 1 auto;
    }
    .btn {
      @media ($phone) {
        min-width: unset;
        width: calc(50%);
        padding: 14px 0;
        margin-bottom: 30px;
      }
    }
  }
  .btn {
    border-radius: 5px;
    border: 1px solid $default_stroke;
    padding: 14px 50px;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    width: min-content;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 165px;
    white-space: nowrap;
    &:hover,
    &.active {
      border: 1px solid $primary_background;
      color: $primary_background;
    }
    .desc-text {
      white-space: normal;
    }
    &.btn-select {
      border: 0;
      border-radius: 8px;
      background-color: $elevation_default;
      padding: 10px 0;
      padding-right: 50px;
      justify-content: flex-start;
      &:hover:not(.btn-dark) {
        background-color: $elevation_default;
        color: $primary_background;
        .checkmark {
          border-color: $primary_background;
        }
      }
    }
    .checkmark {
      border: 1px solid #e3e2e1;
    }
    &.btn-dark {
      background-color: $primary_background;
      color: white;
      &:hover {
        opacity: 0.7;
      }
      .checkmark {
        border-color: $primary_background;
      }
    }
    &.btn-light {
      background-color: white;
      color: $primary_background;
      .checkmark {
        border-color: white;
      }
    }
    &.disable {
      pointer-events: none;
      opacity: 0.2;
    }
    input {
      margin: 0 10px;
      margin-top: -2px;
    }
  }
  .checkState {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 20px;
      width: 14px;
    }
  }

  .checkState {
    span::after {
      content: "";
      width: 5px;
      height: 14px;
      margin-top: -4px;
      border: solid $primary_background;
      border-width: 0 2px 2px 0;
      // display: block;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .experimental-feature {
    color: #ff8a00;
  }
}

html,
body {
  height: 100%;
}

.mat-tooltip {
  background-color: $default_onBackground;
  margin: 4px !important;
  padding: 8px;
  border-radius: 2px !important;
  font-family: Chivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: unset !important;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: $default_onBackground;
    position: absolute;
    bottom: -3px;
    transform: rotate(45deg);
    z-index: 2;
  }
}

.mat-tooltip-panel-below {
  .mat-tooltip {
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: $default_onBackground;
      position: absolute;
      top: -3px;
      transform: rotate(45deg);
      z-index: 2;
    }
  }
}
.mat-tooltip-panel-left {
  .mat-tooltip {
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: $default_onBackground;
      position: absolute;
      right: -3px;
      bottom: unset;
      transform: rotate(45deg);
      z-index: 2;
    }
  }
}
.mat-slider {
  width: 100%;
  &.black {
    height: 10px;

    .mat-slider-thumb-container {
      > div {
        background: #ffffff !important;
        border: 1px solid #dddddd !important;
        box-shadow: 0px 2px 12px rgba(34, 34, 34, 0.12) !important;
        border-radius: 100px;
        width: 28px;
        height: 28px;
        bottom: unset;
        right: -16px;
        // transform: unset;
        display: none;
      }
    }
    .mat-slider-wrapper {
      height: 6px !important;
      right: 0px !important;
      left: 0px !important;
      top: 0 !important;
      > div:not(.mat-slider-thumb-container) {
        border-radius: 6px;
        height: 6px !important;
        > div {
          border-radius: 6px;

          height: 10px !important;
        }
      }
      .mat-slider-thumb-container {
        height: 6px !important;
        top: unset !important;
        display: flex;
        align-items: center;
      }

      .mat-slider-track-wrapper {
        background: linear-gradient(
            90deg,
            $primary_background 99%,
            $elevation_default 1%
        );

        .mat-slider-track-background {
          background-color: $elevation_default !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .mat-slider-track-fill {
          background-color: transparent !important;
        }
      }
    }
  }
  &.gradient {
    .mat-slider-thumb-container {
      > div {
        background: #ffffff !important;
        border: 1px solid #dddddd !important;
        box-shadow: 0px 2px 12px rgba(34, 34, 34, 0.12) !important;
        border-radius: 100px;
        width: 28px;
        height: 28px;
        bottom: unset;
        right: -10px;
        // transform: unset;
      }
    }
    .mat-slider-wrapper {
      height: 16px !important;
      right: 0px !important;
      > div:not(.mat-slider-thumb-container) {
        border-radius: 10px;
        height: 16px !important;
        > div {
          border-radius: 10px;
          height: 16px !important;
        }
      }
      .mat-slider-thumb-container {
        height: 16px !important;
        top: unset !important;
        display: flex;
        align-items: center;
      }

      .mat-slider-track-wrapper {
        background: linear-gradient(
            270deg,
            #fe635c -29.47%,
            #ffc534 9.64%,
            #6f5dc2 80.44%
        );
        -webkit-clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 55%);
        clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 55%);

        .mat-slider-track-background {
          background-color: $elevation_default !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .mat-slider-track-fill {
          background-color: transparent !important;
        }
      }
    }
    .mat-select-panel-wrap {
      .line {
        display: block;
        height: 2px;
        width: calc(100% - 10px);
        background-color: $default_stroke;
        margin: auto;
      }
    }
    .mat-select {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
    }
    .mat-form-field-appearance-legacy {
      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

@keyframes display {
  0% {
    display: none;
  }
  100% {
    display: flex;
  }
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes close {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInToggle {
  0% {
    opacity: 0;
    position: unset;
  }
  100% {
    opacity: 0.6;
    position: unset;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    position: absolute;
  }
  100% {
    opacity: 0;
    position: unset;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    position: unset;
  }
  100% {
    opacity: 1;
    position: unset;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mdc-switch__icon {
  display: none;
}

.mdc-switch__track {
  border-radius: 16px !important;
  height: 24px !important;
  &::before {
    background: $neutralDefault !important;
  }
  &::after {
    background: $Brand-Medium !important;
  }
}
.mdc-switch {
  width: 40px !important;
}

.mdc-switch__handle {
  height: 20px;
  left: 2px !important;
  &::before {
    background-color: white !important;
  }
  &::after {
    background-color: white !important;
  }
}
.mdc-switch--checked {
  .mdc-switch__handle {
    left: -2px !important;
  }
}
.mdc-line-ripple {
  &::before {
    border: 0 !important;
  }
  &::after {
    border: 0 !important;
  }
}
.mat-mdc-form-field {
  border-radius: 4px;
  border: 2px solid $borderLight;
  background: white;
  width: 100%;
  &.mat-focused {
    border: 2px solid $primary_stroke;
  }
  .mat-mdc-text-field-wrapper {
    height: unset !important;
    background: unset !important;
    padding: 0 !important;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-flex {
    height: 42px;
    align-items: center !important;
    flex-direction: row-reverse;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 12px 0 !important;
    width: 34px !important;
    height: 34px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    border-radius: 4px !important;
  }
  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: unset;
  }
  .mat-date-range-input-wrapper {
    display: flex;
    min-width: 74px;
  }
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
  .mat-date-range-input-mirror {
    font-size: $bodySmall;
  }
}

//@TODO add @tailwind base; Integrate tailwind base styles and preflight styles.
@tailwind components;
@tailwind utilities;

//@ TODO add @layer base; Integrate tailwind base styles

@layer components {
  @import '../../../node_modules/@my7n/ui/src/css/tailwind/components/all';
  @import './designSystem/tailwind/components/all';
}

@layer utilities {
  @import '../../../node_modules/@my7n/ui/src/css/tailwind/utilities/all';
}
