// Based on design system available at https://www.figma.com/design/17UMnfV3yTvfjn9HcOTRds/7N20-Design-System
#tailwindStyles {
  // XS
  .icon-12 {
    @apply w-[12px] h-[12px];
  }

  // SM
  .icon-16 {
    @apply w-[16px] h-[16px];
  }

  // M
  .icon-20 {
    @apply w-[20px] h-[20px];
  }

  // L
  .icon-24 {
    @apply w-[24px] h-[24px];
  }

  // XL
  .icon-40 {
    @apply w-[40px] h-[40px];
  }

  // XXL
  .icon-48 {
    @apply w-[48px] h-[48px];
  }
}
